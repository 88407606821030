import React from "react";
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/App/Layout";
import NavbarTwo from "../components/App/NavbarTwo";
import Footer from "../components/App/Footer";
import StarIcon from "../assets/images/star-icon.png";
import Image from "gatsby-image";
import PageBanner from "../components/Common/PageBanner";

const Treatment = ({ data }) => {
  const {
    allContentfulCategory: { nodes },
  } = data;
  const intl = useIntl();
  const products = nodes[0].products;
  const categoryName = intl.locale === "en" ? nodes[0].name : nodes[0].name_fr;
  const description =
    "A leader in the field of clinical solutions for the Surgical, Ophthalmology &amp; Aesthetic markets";
  const canonical = `${process.env.GATSBY_MAIN_URL}/${
    intl.locale === "en" ? "product-category" : "fr/categorie-produit"
  }/${nodes[0].slug}`;

  return (
    <Layout
      title={categoryName}
      description={categoryName + description}
      canonical={canonical}
      image={nodes[0]?.image?.fixed.src}
    >
      <NavbarTwo />
      <div className="courses-details-image">
        <Image fluid={nodes[0]?.image?.fluid} alt={categoryName} />
      </div>
      <PageBanner
        pageTitle={categoryName}
        homePageText={intl.formatMessage({ id: "home" })}
        homePageUrl="/"
        showTitle
        treatMentPageText={intl.formatMessage({ id: "products" })}
        treatMentPageUrl={`/products`}
        activePageText={categoryName}
      />

      <section className="scientist-area">
        <div className="container">
          <div className="section-title">
            <h2 className="sub-title">
              <img src={StarIcon} alt="banner" />
              {`${categoryName}'s `} {intl.formatMessage({ id: "products" })}
            </h2>
          </div>

          <div className="row">
            {products?.map((product) => {
              const productName =
                intl.locale === "en"
                  ? product?.name
                  : product?.nameFr || product?.name;
              const productImage =
                intl.locale === "en"
                  ? product?.image?.fluid
                  : product?.imageFr?.fluid || product?.image?.fluid;
              return (
                <div className="col-lg-4 col-md-6 mt-4 category-product">
                  <div className="single-scientist-item-box">
                    <Link
                      to={`/${intl.locale === "en" ? "product" : "produit"}/${
                        product.slug
                      }`}
                    >
                      <div className="image">
                        <Image
                          className="category-image"
                          fluid={productImage}
                          alt={productName}
                        />
                      </div>
                      <div className="content">
                        <h3>{productName}</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query GetSingleCategoryProducts($slug: String) {
    allContentfulCategory(filter: { slug: { eq: $slug } }) {
      nodes {
        name
        name_fr
        slug
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
        products {
          name
          slug
          nameFr
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          imageFr {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;

export default Treatment;
